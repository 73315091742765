import { AnswerFormElementDto, CopyFormElementsDto, DownloadJobRequestDto, DownloadJobResponseDto, DownloadJobsResponseDto, DynamicNeedsListRequestDto, FormElementsV2ResponseDto, FormElementV2RequestDto, FormElementV2RequestNewDocDto, FormElementV2ResponseDto, htmlDto, LoanReviewStatus, LoanViewType, PackageInfoSharesRequestDto, PackageInfoSharesResponseDto, PackageInfoSharingByLoanDto, StorageType } from 'src/backend';
import { elementsTree } from 'src/slices/elementv2';
import { FormElementV2ResponseDtoExtended, TemplateElementTree } from 'src/types/formelement';
import { getElementLocation } from 'src/utils';
import { isDocumentClean } from 'src/utils/document/is-document-clean';
import { getFormElementEntityType } from 'src/utils/form-element/get-form-element-entity-type';
import { sortFromElements } from 'src/utils/form-element-transformer';

import { baseApi } from './baseApi';

const isElementVirusClean = (element: FormElementV2ResponseDto) => {
    return !element?.answer || isDocumentClean(element.answer.document);
}

const formatDate = (dateString: string) => {
    try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' }).format(date);
    } catch (error) {
        return '';
    }
};

export const mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended = (response: FormElementsV2ResponseDto) => {
    const packageInfos = Object.values(response.packageInfo);
    const elements: FormElementV2ResponseDtoExtended[] = packageInfos.map((packageInfo) => ({
        ...response['elements']?.[packageInfo.elementId],
        id: packageInfo.id,
        parentId: packageInfo.parentInfoId ?? null,
        elementId: packageInfo.elementId,
        description: packageInfo.description,
        includeDescription: packageInfo.includeDescription,
        childrenIds: packageInfo.childrenIds,
        title: packageInfo.title,
        priorityType: packageInfo.priorityType,
        sharedInfo: response['sharedInfo']?.[packageInfo.id] ?? [],
        locations: packageInfo.locations,
        visibleAtStatus: packageInfo.visibleAtStatus,
        entityType: getFormElementEntityType(response['elements']?.[packageInfo.elementId]),
        isVirusClean: isElementVirusClean(response['elements']?.[packageInfo.elementId]),
        lastModifiedDateFormatted: response['elements']?.[packageInfo.elementId]?.lastModifiedDate ?
            formatDate(response['elements']?.[packageInfo.elementId]?.lastModifiedDate)
            : '',
        children: [],
        path: getElementLocation(packageInfo.id, packageInfos.map(info => ({
            parentId: info.parentInfoId,
            id: info.id,
            title: info.parentInfoId ? info.title : 'Package',
            loanId: response['elements'][info.elementId].loanId,
            entityType: getFormElementEntityType(response['elements']?.[info.elementId]),
            storageType: response['elements'][info.elementId].storageType
        })), true).reverse()
    }));
    const links = Object.values(response.linkInfo ?? {}).map((linkInfo) => ({
        ...response['elements']?.[linkInfo.elementId],
        id: linkInfo.id,
        parentId: linkInfo.parentInfoId ?? null,
        elementId: linkInfo.elementId,
        description: linkInfo.description,
        includeDescription: linkInfo.includeDescription,
        childrenIds: linkInfo.childrenIds,
        title: linkInfo.title,
        priorityType: linkInfo.priorityType,
        locations: linkInfo.locations,
        sharedInfo: response['sharedInfo']?.[linkInfo.id] ?? [],
        isVirusClean: isElementVirusClean(response['elements']?.[linkInfo.elementId]),
        visibleAtStatus: linkInfo.visibleAtStatus,
        lastModifiedDateFormatted: response['elements']?.[linkInfo.elementId]?.lastModifiedDate ? formatDate(response['elements']?.[linkInfo.elementId]?.lastModifiedDate) : '',
        entityType: getFormElementEntityType(response['elements']?.[linkInfo.elementId]),
        children: [],
        path: []
    }));

    const parentId = elements.find((folder) => !folder.parentId)?.id;

    const tree = elementsTree<FormElementV2ResponseDtoExtended>(elements, 'id', parentId ? [parentId] : []);
    return {
        tree,
        links,
        rootElementId: parentId,
        totalFileElements: Object.values(response.elements ?? {}).filter((element) => element.storageType === 'FILE').length,
        totalFolderElements: Object.values(response.elements ?? {}).filter((element) => element.storageType === 'FOLDER').length,
        // @ts-expect-error
        list: [...elements].sort(sortFromElements)
    };
}

export interface PackageState {
    totalFileElements: number;
    totalFolderElements: number;
    tree: TemplateElementTree<FormElementV2ResponseDtoExtended>[],
    list: (FormElementV2ResponseDtoExtended)[],
    links: FormElementV2ResponseDtoExtended[],
    rootElementId: string
}

export const packageApi = baseApi.enhanceEndpoints({ addTagTypes: ['BasicLoanDto', "LoanDto", "DownloadJobResponseDto", 'ConsolidatedTasksDto', 'FormElementsV2ResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        createElements: build.mutation<{
            totalFileElements: number;
            totalFolderElements: number;
            tree: TemplateElementTree<FormElementV2ResponseDtoExtended>[],
            list: (FormElementV2ResponseDtoExtended)[]
        }, {
            elements: (Partial<FormElementV2RequestDto> & { loanId: string, storageType: StorageType })[];
            multiSelect: boolean;
        }>({
            query: (data) => ({
                url: '/v2/loanmanager/elements',
                method: 'POST',
                data
            }),
            transformResponse: (response: FormElementsV2ResponseDto) => {
                return mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(response);
            },
            invalidatesTags: (_res, _err, { elements }) => [
                'FormElementsV2ResponseDto',
                ...elements.map<{ type: "ConsolidatedTasksDto", id: string }>(({ loanId }) => ({
                    type: "ConsolidatedTasksDto",
                    id: loanId
                })),
                ...elements.map<{ type: "FormElementsV2ResponseDto", id: string }>(({ loanId }) => ({
                    type: "FormElementsV2ResponseDto",
                    id: loanId
                })),
                ...elements.map<{ type: 'BasicLoanDto', id: string }>(({ loanId }) => ({
                    type: 'BasicLoanDto',
                    id: loanId
                }))
            ]
        }),
        updateElements: build.mutation<FormElementsV2ResponseDto, {
            elements: (Partial<FormElementV2RequestDto> & { id: string, loanId: string })[];
            multiSelect: boolean;
        }>({
            query: (data) => ({
                url: '/v2/loanmanager/elements',
                method: 'PUT',
                data
            }),
            invalidatesTags: (_res, _err, { elements }) => [
                'FormElementsV2ResponseDto',
                ...elements.map<{ type: "ConsolidatedTasksDto", id: string }>(({ loanId }) => ({
                    type: "ConsolidatedTasksDto",
                    id: loanId
                })),
                ...elements.map<{ type: "FormElementsV2ResponseDto", id: string }>(({ id }) => ({
                    type: "FormElementsV2ResponseDto",
                    id
                })),
                ...elements.map<{ type: 'BasicLoanDto', id: string }>(({ loanId }) => ({
                    type: 'BasicLoanDto',
                    id: loanId
                })),
                ...elements.map<{ type: 'LoanDto', id: string }>(({ loanId }) => ({
                    type: 'LoanDto',
                    id: loanId
                })),
            ]
        }),
        deleteElements: build.mutation<FormElementsV2ResponseDto, {
            elements: { id: string, loanId: string, storageType: StorageType }[];
            multiSelect: boolean;
        }>({
            query: (data) => ({
                url: '/v2/loanmanager/elements',
                method: 'DELETE',
                data
            }),
            invalidatesTags: (_res, _err, { elements }) => [
                'FormElementsV2ResponseDto',
                ...elements.map<{ type: "ConsolidatedTasksDto", id: string }>(({ loanId }) => ({
                    type: "ConsolidatedTasksDto",
                    id: loanId
                })),
                ...elements.map<{ type: "FormElementsV2ResponseDto", id: string }>(({ id }) => ({
                    type: "FormElementsV2ResponseDto",
                    id
                })),
                ...elements.map<{ type: 'BasicLoanDto', id: string }>(({ loanId }) => ({
                    type: 'BasicLoanDto',
                    id: loanId
                })),
                ...elements.map<{ type: 'LoanDto', id: string }>(({ loanId }) => ({
                    type: 'LoanDto',
                    id: loanId
                }))
            ]
        }),
        getLoanReviewStatusElements: build.query<{
            totalFileElements: number;
            totalFolderElements: number;
            tree: TemplateElementTree<FormElementV2ResponseDtoExtended>[],
            list: (FormElementV2ResponseDtoExtended)[]
        }, { id: string, loanReviewStatus: LoanReviewStatus }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.id}/statusChangeElements`,
                method: 'GET',
                data: { loanReviewStatus: data.loanReviewStatus }
            }),
            providesTags: (res, error, args) => [
                { type: 'FormElementsV2ResponseDto', id: args.id },
                ...(!error
                    ? res.list.map<{ type: 'FormElementsV2ResponseDto', id: string }>((packageInfo) => ({
                        type: 'FormElementsV2ResponseDto',
                        id: packageInfo.id
                    }))
                    : [])
            ],
            transformResponse: (response: FormElementsV2ResponseDto) => {
                return mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(response);
            }
        }),
        getLoansElements: build.query<PackageState, { ids: string[], view: LoanViewType }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                // if we don't have any ids, we don't need to make any requests
                if (_arg.ids.length === 0) {
                    return {
                        data: {
                            totalFileElements: 0,
                            totalFolderElements: 0,
                            tree: [],
                            list: [],
                            links: [],
                            rootElementId: null
                        }, error: null
                    };
                }
                const promises = _arg.ids.map(id => fetchWithBQ({ url: `/v2/loanmanager/${id}/elements`, method: 'GET', data: { view: _arg.view } }));
                const results = await Promise.all(promises);
                const data = results.map(result => result.data as FormElementsV2ResponseDto)
                const transformedData = data.map(mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended);
                const mergedDataTransformed = transformedData.reduce((acc, curr) => {
                    return {
                        ...acc,
                        totalFileElements: acc.totalFileElements + curr.totalFileElements,
                        totalFolderElements: acc.totalFolderElements + curr.totalFolderElements,
                        tree: [...acc.tree, ...curr.tree],
                        list: [...acc.list, ...curr.list],
                        links: [...acc.links, ...curr.links],
                        rootElementId: acc.rootElementId ?? curr.rootElementId
                    }
                }, {
                    totalFileElements: 0,
                    totalFolderElements: 0,
                    tree: [],
                    list: [],
                    links: [],
                    rootElementId: null
                } as PackageState);
                return { data: mergedDataTransformed, error: null };
            },
            providesTags: (res, __, args) => [
                { type: "FormElementsV2ResponseDto", id: "LIST" },
                ...args.ids.map<{ type: 'FormElementsV2ResponseDto', id: string }>((id) => ({
                    type: 'FormElementsV2ResponseDto',
                    id
                })),
                ...res.list.map<{ type: 'FormElementsV2ResponseDto', id: string }>((packageInfo) => ({
                    type: 'FormElementsV2ResponseDto',
                    id: packageInfo.id
                })),
            ]
        }),
        copyElements: build.mutation<{
            totalFileElements: number;
            totalFolderElements: number;
            tree: TemplateElementTree<FormElementV2ResponseDtoExtended>[],
            list: (FormElementV2ResponseDtoExtended)[]
        }, CopyFormElementsDto>({
            query: (data) => ({
                url: '/v2/loanmanager/elements/copy',
                method: 'POST',
                data
            }),
            transformResponse: (response: FormElementsV2ResponseDto) => {
                return mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(response);
            },
            invalidatesTags: (_res, _err, { loanId, sourceIds }) => [
                'FormElementsV2ResponseDto',
                ...sourceIds.map<{ type: "ConsolidatedTasksDto", id: string }>(() => ({
                    type: "ConsolidatedTasksDto",
                    id: loanId
                })),
                ...sourceIds.map<{ type: "FormElementsV2ResponseDto", id: string }>((id) => ({
                    type: "FormElementsV2ResponseDto",
                    id
                })),
                ...sourceIds.map<{ type: 'BasicLoanDto', id: string }>(() => ({
                    type: 'BasicLoanDto',
                    id: loanId
                })),
                ...sourceIds.map<{ type: 'LoanDto', id: string }>(() => ({
                    type: 'LoanDto',
                    id: loanId
                }))
            ]
        }),
        getLoanElements: build.query<PackageState, { id: string, view: LoanViewType }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.id}/elements`,
                method: 'GET',
                data: { view: data.view }
            }),
            providesTags: (res, error, args) => [
                { type: 'FormElementsV2ResponseDto', id: "LIST" },
                { type: 'FormElementsV2ResponseDto' as const, id: args.id },
                ...(!error
                    ? res.list.map<{ type: 'FormElementsV2ResponseDto', id: string }>((packageInfo) => ({
                        type: 'FormElementsV2ResponseDto' as const,
                        id: packageInfo.id
                    }))
                    : [])
            ],
            transformResponse: (response: FormElementsV2ResponseDto) => {
                return mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(response);
            }
        }),
        addAnswerToElement: build.mutation<FormElementsV2ResponseDto, AnswerFormElementDto>({
            query: (data) => ({
                url: '/v2/loanmanager/elements/answer',
                method: 'POST',
                data
            }),
            invalidatesTags: (_, __, args) => [
                'FormElementsV2ResponseDto',
                // { type: 'FormElementsV2ResponseDto', id: "LIST" },
                {
                    type: 'FormElementsV2ResponseDto',
                    id: args.elementId
                },
                { type: 'ConsolidatedTasksDto', id: args.elementId },
                {
                    type: 'BasicLoanDto',
                    id: "LIST"
                },
                {
                    type: 'LoanDto',
                    id: "LIST"
                }
            ]
        }),
        deleteAnswerFromElement: build.mutation<FormElementsV2ResponseDto, AnswerFormElementDto>({
            query: (data) => ({
                url: '/v2/loanmanager/elements/answer',
                method: 'DELETE',
                data
            }),
            invalidatesTags: (_, __, args) => [
                'FormElementsV2ResponseDto',
                {
                    type: 'FormElementsV2ResponseDto',
                    id: args.elementId
                },
                { type: 'ConsolidatedTasksDto', id: args.elementId },
                {
                    type: 'BasicLoanDto',
                    id: "LIST"
                },
                {
                    type: 'LoanDto',
                    id: "LIST"
                }
            ]
        }),
        createSharedInfoElement: build.mutation<PackageInfoSharesResponseDto, PackageInfoSharesRequestDto>({
            query: (data) => ({
                url: '/v2/loanmanager/shared/elements',
                method: 'POST',
                data
            }),
            invalidatesTags: (_res, _err, { shares }) => [
                {
                    type: 'ConsolidatedTasksDto',
                    id: "LIST"
                },
                ...shares.map<{ type: 'ConsolidatedTasksDto', id: string }>(({ loanId }) => ({
                    type: 'ConsolidatedTasksDto',
                    id: loanId
                })),
                ...shares.map<{ type: 'FormElementsV2ResponseDto', id: string }>(({ loanId }) => ({
                    type: 'FormElementsV2ResponseDto',
                    id: loanId
                })),
                ...shares.map<{ type: 'BasicLoanDto', id: string }>(({ loanId }) => ({
                    type: 'BasicLoanDto',
                    id: loanId
                })),
                ...shares.map<{ type: 'LoanDto', id: string }>(({ loanId }) => ({
                    type: 'LoanDto',
                    id: loanId
                }))
            ]
        }),
        getLoanElementsZip: build.query<DownloadJobsResponseDto, { loanId: string }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.loanId}/elementsDownload`,
                method: 'GET'
            })
        }),
        getLoansElementsZip: build.query<DownloadJobResponseDto[], { loanIds: string[] }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const promises = _arg.loanIds.map(id => fetchWithBQ({ url: `/v2/loanmanager/${id}/elementsDownload`, method: 'GET' }));
                const results = await Promise.all(promises);
                const data = results.map(result => result.data as DownloadJobsResponseDto);
                try {
                    return data.reduce((acc, curr) => {
                        return {
                            data: [
                                ...acc.data,
                                ...curr.jobs
                            ],
                            error: null
                        }
                    }, { data: [], error: null });
                } catch (error) {
                    return { data: [], error };
                }
            },
            providesTags: ['DownloadJobResponseDto']
        }),
        generateElementsZip: build.mutation<DownloadJobResponseDto, { loanId: string, data: DownloadJobRequestDto }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.loanId}/elementsDownload`,
                method: 'POST',
                data: data.data
            }),
            invalidatesTags: ['DownloadJobResponseDto']
        }),
        deleteElementsZip: build.mutation<DownloadJobResponseDto, { loanId: string, jobId: string }>({
            query: (data) => ({
                url: `/v2/loanmanager/${data.loanId}/elementsDownload?jobid=${data.jobId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['DownloadJobResponseDto']
        }),
        getSharedElementsByLoanId: build.query<Record<string, PackageState>, { view?: LoanViewType; companyid: string; }>({
            query: (data) => ({
                url: `/v2/loanmanager/shared/elementsbyloan`,
                method: 'GET',
                data
            }),
            providesTags: (res, error) => {
                if (error) {
                    return [];
                }
                return [
                    { type: 'FormElementsV2ResponseDto', id: 'LIST' },
                    ...Object.keys(res).map<{ type: 'FormElementsV2ResponseDto', id: string }>((loanId) => ({
                        type: 'FormElementsV2ResponseDto',
                        id: loanId
                    })),
                ]
            },
            transformResponse: (response: PackageInfoSharingByLoanDto) => {
                return Object.keys(response.sharesByLoan).reduce((acc, key) => {
                    const transformedData = mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(response.sharesByLoan[key]);
                    return {
                        ...acc,
                        [key]: transformedData
                    }
                }, {} as Record<string, PackageState>);
            }
        }),
        deleteSharedInfoElement: build.mutation<PackageInfoSharesResponseDto, PackageInfoSharesRequestDto>({
            query: (data) => ({
                url: '/v2/loanmanager/shared/elements',
                method: 'DELETE',
                data
            }),
            invalidatesTags: (_res, _err, { shares }) => [
                {
                    type: 'ConsolidatedTasksDto',
                    id: "LIST"
                },
                ...shares.map<{ type: 'FormElementsV2ResponseDto', id: string }>(({ loanId }) => ({
                    type: 'FormElementsV2ResponseDto',
                    id: loanId
                })),
                ...shares.map<{ type: 'ConsolidatedTasksDto', id: string }>(({ loanId }) => ({
                    type: 'ConsolidatedTasksDto',
                    id: loanId
                })),
                ...shares.map<{ type: 'BasicLoanDto', id: string }>(({ loanId }) => ({
                    type: 'BasicLoanDto',
                    id: loanId
                })),
                ...shares.map<{ type: 'LoanDto', id: string }>(({ loanId }) => ({
                    type: 'LoanDto',
                    id: loanId
                }))
            ]
        }),
        createEmptySharepointDocument: build.mutation<{
            totalFileElements: number;
            totalFolderElements: number;
            tree: TemplateElementTree<FormElementV2ResponseDtoExtended>[],
            list: (FormElementV2ResponseDtoExtended)[]
        }, Pick<FormElementV2RequestNewDocDto, 'documentType' | 'newDocumentTitle' | 'parentId' | 'loanId' | 'storageType'>>({
            query: (data) => ({
                url: '/v2/loanmanager/element/newdoc',
                method: 'POST',
                data
            }),
            transformResponse: (response: FormElementsV2ResponseDto) => {
                return mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(response);
            },
            invalidatesTags: ({ list: elements }, _err) => [
                'FormElementsV2ResponseDto',
                ...elements.map<{ type: "ConsolidatedTasksDto", id: string }>(({ loanId }) => ({
                    type: "ConsolidatedTasksDto",
                    id: loanId
                })),
                ...elements.map<{ type: "FormElementsV2ResponseDto", id: string }>(({ loanId }) => ({
                    type: "FormElementsV2ResponseDto",
                    id: loanId
                })),
                ...elements.map<{ type: 'BasicLoanDto', id: string }>(({ loanId }) => ({
                    type: 'BasicLoanDto',
                    id: loanId
                }))
            ]
        }),
        generateDynamicNeedsListBody: build.query<string, DynamicNeedsListRequestDto>({
            query: (data) => ({
                url: '/v1/messages/generateDynamicNeedsListBody',
                method: 'POST',
                data
            }),
            transformResponse: (response: htmlDto) => response.html
        }),
        copyAnswerToElement: build.mutation<FormElementV2ResponseDto, AnswerFormElementDto>({
            query: (data) => ({
                url: '/v2/loanmanager/elements/copyanswer',
                method: 'POST',
                data
            }),
            invalidatesTags: (_, __, args) => [
                'FormElementsV2ResponseDto',
                {
                    type: 'FormElementsV2ResponseDto',
                    id: args.elementId
                },
                { type: 'ConsolidatedTasksDto', id: args.elementId },
                {
                    type: 'BasicLoanDto',
                    id: "LIST"
                },
                {
                    type: 'LoanDto',
                    id: "LIST"
                }
            ]
        }),
    }),
    overrideExisting: true,
})

export const {
    useCopyAnswerToElementMutation,
    useCreateEmptySharepointDocumentMutation,
    useGetSharedElementsByLoanIdQuery,
    useCopyElementsMutation,
    useGenerateElementsZipMutation,
    useDeleteElementsZipMutation,
    useGetLoansElementsZipQuery,
    useLazyGenerateDynamicNeedsListBodyQuery,
    useGenerateDynamicNeedsListBodyQuery,
    useGetLoansElementsQuery,
    useDeleteSharedInfoElementMutation,
    useDeleteElementsMutation,
    useCreateSharedInfoElementMutation,
    useAddAnswerToElementMutation,
    useGetLoanElementsQuery,
    useCreateElementsMutation,
    useDeleteAnswerFromElementMutation,
    useUpdateElementsMutation,
    useLazyGetLoanReviewStatusElementsQuery,
    useLazyGetLoanElementsQuery,
} = packageApi;



