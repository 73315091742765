
import { EmailSignatureDto } from 'src/backend';

import { baseApi } from './baseApi';

export const emailSignatureApi = baseApi.enhanceEndpoints({ addTagTypes: ['EmailSignatureDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getSignaturesForUser: build.query<EmailSignatureDto[], { userId: string, companyId }>({
            query: (data) => ({
                url: `/v2/signature`,
                method: 'GET',
                data,
            }),
            providesTags: ['EmailSignatureDto'],
        }),
        updateSignature: build.mutation<EmailSignatureDto, EmailSignatureDto>({
            query: (data) => ({
                url: `/v2/signature/${data.id}`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['EmailSignatureDto'],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetSignaturesForUserQuery,
    useUpdateSignatureMutation,
} = emailSignatureApi;



