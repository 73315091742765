import { NoteObjectType, NoteRequestDto, NoteResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

export const noteApi = baseApi.enhanceEndpoints({ addTagTypes: ['NoteResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        createNote: build.mutation<NoteResponseDto, NoteRequestDto>({
            query: (data) => ({
                url: `/v2/notes`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['NoteResponseDto']
        }),
        getNotesForLoan: build.query<NoteResponseDto[], { loanId: string, objectId?: string; objectType?: NoteObjectType }>({
            query: (data) => ({
                url: `/v2/notes/loan/${data.loanId}`,
                method: 'GET',
                data
            }),
            providesTags: ['NoteResponseDto']
        }),
        updateNote: build.mutation<NoteResponseDto, Partial<NoteRequestDto>>({
            query: (data) => ({
                url: `/v2/notes/${data.id}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['NoteResponseDto']
        }),
        deleteNote: build.mutation<NoteResponseDto, { id: string }>({
            query: (data) => ({
                url: `/v2/notes/${data.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['NoteResponseDto']
        }),
        notifyNoteUsers: build.mutation<NoteResponseDto, { id: string, iDto: NoteRequestDto }>({
            query: (data) => ({
                url: `/v2/notes/${data.id}/notify`,
                method: 'PUT',
                data: data.iDto
            }),
            invalidatesTags: ['NoteResponseDto']
        }),
    }),
    overrideExisting: true,
})

export const {
    useCreateNoteMutation,
    useNotifyNoteUsersMutation,
    useGetNotesForLoanQuery,
    useUpdateNoteMutation,
    useDeleteNoteMutation,
    useLazyGetNotesForLoanQuery
} = noteApi;



